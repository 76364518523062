/* LandingAnimation.css */

.landing-animation {
	opacity: 0;
	animation: landing 0.3s forwards;
}

@keyframes landing {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
