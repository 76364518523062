.blur-to-clear-enter {
	opacity: 0;
	filter: blur(10px);
}
.blur-to-clear-enter-active {
	opacity: 1;
	filter: blur(0px);
	transition: all 700ms ease-out;
}
.blur-to-clear-exit {
	opacity: 1;
}
.blur-to-clear-exit-active {
	opacity: 0;
	filter: blur(10px);
	transition: all 300ms ease-out;
}
