/* MassAddForm.css */

.mass-add-form {
    width: 100%;
    height: 100%;
    min-height: 75px;
    display: flex;
    /* align-items: start; */ /* This is adjusted in the content */
    background-color: #fafafa; /* ColorScheme.lighterGrey */
    padding: 20px 30px;
    box-sizing: border-box;
    border: 1px solid rgb(217, 217, 217); /* ColorScheme.textFieldBorder */
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    gap: 20px;
    cursor: pointer;
    align-items: center;
  }

  .mass-add-form-content {
    width: 100%;
    height: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 20px;
  }

  .mass-add-form-title {
   color: rgba(0, 0, 0, 0.6); /* ColorScheme.lighterGrey */
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding-top: 3px;
  }
  
  .mass-add-form .icon-box {
    width: 20px;
    height: 25px;
  }
  
  .mass-add-form .form-box {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
  }
  
  .mass-add-form .form-box label {
    display: block;
    width: 100%;
  }
  
  .mass-add-form .form-box input[type="file"] {
    /* border: 1px solid red; */
    background-color: #F5F5F5;
  }

  @keyframes wave {
    0% { color: black; }
    50% { color: rgba(255, 255, 255, 0.10); }
    100% { color: black; }
  }

  @keyframes shrink {
    from {
      height: auto;
      opacity: 1;
    }
    to {
      height: 0;
      opacity: 0;
    }
  }
  
  @keyframes grow {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: auto;
      opacity: 1;
    }
  }

  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }