
abbr[title] {
	text-decoration: none;
}

.tolotech-calendar {
	font-family: Sofia sans !important;
	font-size: 1.25em;
	padding: 20px;
	border-radius: 8px;
	border-color: #fff;
}

.tolotech-calendar * {
	font-family: inherit;
}

/* .tolotech-calendar .react-calendar__navigation {
    font-size: 16px;
} */

/* react-calendar__navigation__arrow react-calendar__navigation__prev-button */

.react-calendar__navigation__label__labelText,
.react-calendar__year-view__months__month {
	font-size: 18px;
	font-weight: 500;
	text-transform: capitalize;
}

.react-calendar__navigation button:enabled:hover {
	background-color: transparent;
}

.react-calendar__navigation__arrow {
	font-size: 28px;
	color: #27293735;
}

.react-calendar__navigation {
	align-items: center;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px #27293712 solid;
}

.tolotech-calendar .react-calendar__month-view__days__day--weekend {
	color: #000;
}

.tolotech-calendar .react-calendar__month-view__weekdays {
	font-weight: lighter;
	font-size: 0.7em;
	color: #000;
	text-transform: capitalize;
	padding-bottom: 1em;
}

.tolotech-calendar .react-calendar__tile--now {
	background: #fff;
}

.tolotech-calendar .react-calendar__tile--range,
.react-calendar__tile--now {
	background: #1991ff10;
	color: rgb(28, 86, 143);
	z-index: 1;
}

.tolotech-calendar .react-calendar__tile--active:first {
	margin-left: -3px;
	padding-left: 15px;
}

/* .tolotech-calendar .react-calendar__tile--range {
	margin-left: -10px;
	z-index: 2;
}

.tolotech-calendar .react-calendar__tile--range:last-of-type {
	margin-right: -10px;
	z-index: 2;
} */

.tolotech-calendar .react-calendar__tile--now:enabled:hover {
	background: rgb(28, 86, 143);
}

.tolotech-calendar .react-calendar__tile:enabled:hover,
.tolotech-calendar .react-calendar__tile:enabled:focus {
	background-color: rgb(28, 86, 143);
	border-radius: 8px;
	color: #fff;
}

.tolotech-calendar .react-calendar__tile--rangeStart,
.tolotech-calendar .react-calendar__tile--rangeEnd {
	background: rgb(28, 86, 143);
	color: #fff;
	border-radius: 8px;
	z-index: 900;
}

.tolotech-calendar .react-calendar__month-view__days {
	row-gap: 5px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: 1991ff;
}

.react-calendar__tile--now.react-calendar__tile--inRange,
.react-calendar__tile--now.react-calendar__tile--hover,
.react-calendar__tile--inRange:hover {
    background: rgb(230, 230, 230) !important;
    color: black !important;
}


/* react-calendar__tile react-calendar__tile--hasActive react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__month-view__days__day */
