.slide-from-bottom-enter {
	opacity: 0;
    transform: translateY(100px);
}

.slide-from-bottom-enter-active {
	opacity: 1;
    transform: translateY(0);
	transition: all 400ms cubic-bezier(.01,.64,0,1);
}

.slide-from-bottom-exit {
    transform: translateY(0);
	opacity: 1;
}

.slide-from-bottom-exit-active {
	opacity: 0;
    transform: translateY(100px);
	transition: all 300ms ease-in-out;
}
