.on-off-switch {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 68px;
	height: 40px; /* Set a fixed height for the container, use lineheights */
}

.upper-box {
	height: 11.5px; /* fixed height to align center text with device name, ( 64-17 ) / 2 */
}

.lower-box {
	width: 100%;
	display: flex;
	flex-direction: column-reverse; /* Adjusted */
	justify-content: flex-end; /* Adjusted */
	align-items: flex-end; /* Adjusted */
}

.text-box {
	height: 16px;
	text-align: right;
}

.switch {
	position: relative;
	display: inline-block;
	width: 68px;
	height: 17px;
	/* margin: 0 10px; */
}

.switch input {
	display: none;
}

.slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	border-radius: 34px;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	border-radius: 50%;
	transition: 0.4s;
}

.switch input:checked + .slider {
	background-color: #75a85d;
}

.switch input:checked + .slider:before {
	transform: translateX(51px);
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-in-out;
}
