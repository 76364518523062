/* ListGroupingSlider.css */

.list-fields-container {
	overflow-x: auto;
	overflow-y: hidden;
	max-width: 100%;
	display: flex;
	width: 100%;
	background-color: "#1C568F";
}

.list-fields-container-wrapper {
	max-width: 100%;
	display: flex;
	border-top: 2px solid rgba(255, 255, 255, 0.1);
	border-bottom: 2px solid rgba(255, 255, 255, 0.1);
	overflow: hidden;
	margin-bottom: 50px;
}

.list-field {
	display: flex;
	transition: background-color 0.3s;
	/* flex-basis: calc(
		33.33% - 30px
	);  */
	/* margin: 15px; */
	padding: 15px;
	cursor: pointer;
	justify-content: center;
	flex-direction: row;
	transition: background-color 0.3s;
	color: white;
	line-break: auto;
	align-items: center;
	border-color: transparent;
}

.list-field.selected {
	box-sizing: border-box;
	transition: border-color 0.5s;
}
