.discount-selector {
	width: 100px;
	height: 32px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
}

.discount-selector button {
	flex: 1;
	border: none;
	background-color: #e0e0e0;
	color: black;
	transition: background-color 0.5s ease-in-out;
}

.discount-selector button.active {
	background-color: #1c568f;
	color: white;
}
